import { Routes } from '@angular/router';
import { unauthGuard } from './guards/unauth.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/navbar/navbar.component').then(
        (m) => m.NavbarComponent
      ),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./pages/landing-page/landing-page.component').then(
            (m) => m.LandingPageComponent
          ),
      },
      {
        path: 'login',
        canActivate: [unauthGuard],
        loadComponent: () =>
          import('./pages/login/login.component').then((m) => m.LoginComponent),
      },
      {
        path: 'change-password',
        canActivate: [unauthGuard],
        loadComponent: () =>
          import('./pages/change-password/change-password.component').then(
            (m) => m.ChangePasswordComponent
          ),
      },
      {
        path: 'register-real-estate',
        canActivate: [unauthGuard],
        loadComponent: () =>
          import('./pages/register/register.component').then(
            (m) => m.RegisterComponent
          ),
      },
      {
        path: 'home',
        loadComponent: () =>
          import('./pages/home/home.component').then((m) => m.HomeComponent),
      },
      {
        path: 'property',
        children: [
          {
            path: ':type/:id',
            loadComponent: () =>
              import('./pages/detail-property/detail-property.component').then(
                (m) => m.DetailPropertyComponent
              ),
          },
          {
            path: ':id',
            loadComponent: () =>
              import('./pages/detail-property/detail-property.component').then(
                (m) => m.DetailPropertyComponent
              ),
          },
        ],
      },
      {
        path: 'order',
        children: [
          {
            path: ':type/:id',
            loadComponent: () =>
              import('./pages/detail-order/detail-order.component').then(
                (m) => m.DetailOrderComponent
              ),
          },
          {
            path: ':id',
            loadComponent: () =>
              import('./pages/detail-order/detail-order.component').then(
                (m) => m.DetailOrderComponent
              ),
          },
        ],
      },
      {
        path: 'register-advertisement',
        // canActivate: [AuthGuard], // * Caso o projeto tenha rotas sem auth além de login, remover essa linha.
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './pages/register-advertisement/register-advertisement.component'
              ).then((m) => m.RegisterAdvertisementComponent),
          },
          {
            path: 'property',
            loadComponent: () =>
              import(
                './pages/register-advertisement/register-property/register-property.component'
              ).then((m) => m.RegisterPropertyComponent),
          },
          {
            path: 'order',
            loadComponent: () =>
              import(
                './pages/register-advertisement/register-order/register-order.component'
              ).then((m) => m.RegisterOrderComponent),
          },
        ],
      },
      {
        path: 'register-plan',
        // canActivate: [AuthGuard], // * Caso o projeto tenha rotas sem auth além de login, remover essa linha.
        loadComponent: () =>
          import('./pages/register-plan/register-plan.component').then(
            (m) => m.RegisterPlanComponent
          ),
      },
      {
        path: 'profile',
        // canActivate: [AuthGuard], // * Caso o projeto tenha rotas sem auth além de login, remover essa linha.
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./pages/profile/profile.component').then(
                (m) => m.ProfileComponent
              ),
          },
          {
            path: 'property/:id',
            loadComponent: () =>
              import(
                './pages/register-advertisement/register-property/register-property.component'
              ).then((m) => m.RegisterPropertyComponent),
          },
          {
            path: 'order/:id',
            loadComponent: () =>
              import(
                './pages/register-advertisement/register-order/register-order.component'
              ).then((m) => m.RegisterOrderComponent),
          },
        ],
      },
      {
        path: 'payment',
        // canActivate: [AuthGuard], // * Caso o projeto tenha rotas sem auth além de login, remover essa linha.
        children: [
          {
            path: ':id',
            loadComponent: () =>
              import('./pages/payment-method/payment-method.component').then(
                (m) => m.PaymentMethodComponent
              ),
          },
          {
            path: ':id/:type_payment',
            loadComponent: () =>
              import('./pages/payment/payment.component').then(
                (m) => m.PaymentComponent
              ),
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
