import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { StorageService } from '../services/storage.service';

export const unauthGuard: CanActivateFn = () => {
  const storage = inject(StorageService);
  if (storage.token) {
    return false;
  }

  return true;
};
