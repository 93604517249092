import { CommonModule } from '@angular/common';
import { afterNextRender, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  constructor() {
    if (environment.production) {
      afterNextRender(() => {
        console.log('Production mode enabled');
        document.addEventListener('contextmenu', (e) => {
          e.preventDefault();
        });
      });
    }
  }
}
